import React, { useState, useEffect } from 'react'
import { X, ChevronDown, ChevronUp } from 'lucide-react'
import { useTranslation } from 'react-i18next';
import { cn } from '../lib/utils';
import { Button } from './ui/button';


interface CookiePreference {
  id: string
  name: string
  defaultValue: boolean;
  disabled: boolean;
}

export default function CookieConsentOverlay() {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [preferences, setPreferences] = useState<Record<string, boolean>>({})

  const cookieOptions = t('configs.cookies.list', { returnObjects: true }) as CookiePreference[]

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent')
    if (!consent) {
      setIsOpen(true)
      setPreferences(
        cookieOptions.reduce((acc, option) => ({
          ...acc,
          [option.id]: option.defaultValue
        }), {})
      )
    } else {
      setPreferences(JSON.parse(consent))
    }
  }, [])

  const handleAcceptAll = () => {
    const allAccepted = cookieOptions.reduce((acc, option) => ({
      ...acc,
      [option.id]: true
    }), {})
    setPreferences(allAccepted)
    localStorage.setItem('cookieConsent', JSON.stringify(allAccepted))
    setIsOpen(false)
  }

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(preferences))
    setIsOpen(false)
  }

  const handleTogglePreference = (id: string) => {
    if (id === 'necessary') return // Assuming 'necessary' cookies can't be toggled
    setPreferences(prev => ({ ...prev, [id]: !prev[id] }))
  }

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4">
      <div className="bg-pallete_3 rounded-sm shadow-xl max-w-md w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl font-semibold font-didact_gothic">{t('configs.cookies.title')}</h1>
            <button onClick={() => setIsOpen(false)} className="text-gray-500 hover:text-gray-700">
              <X size={24} />
            </button>
          </div>
          <p className="text-gray-600 mb-4">
            {t('configs.cookies.description')}
          </p>
          <div className="mb-4">
            <button
              onClick={() => setShowDetails(!showDetails)}
              className="flex items-center text-pallete_2 hover:text-pallete_1 font-didact_gothic"
            >
              {showDetails ? t('configs.cookies.hideDetails') : t('configs.cookies.showDetails')}
              {showDetails ? <ChevronUp size={16} className="ml-1" /> : <ChevronDown size={16} className="ml-1" />}
            </button>
          </div>
          {showDetails && (
            <div className="mb-4 space-y-2">
              {cookieOptions.map((option) => (
                <div key={option.id} className="flex items-center justify-between">
                  <span className="text-gray-700 capitalize font-didact_gothic">{option.name}</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={preferences[option.id]}
                      onChange={() => handleTogglePreference(option.id)}
                      disabled={option.disabled}
                    />
                    <div className={cn("w-11 h-6 bg-pallete_3 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-pallete_2 dark:peer-focus:ring-pallete_2 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-pallete_3 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-pallete_3 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-pallete_2",
                      option.disabled && 'opacity-30'
                    )}></div>
                  </label>
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-end space-x-4 w-full overflow-hidden">
            <Button onClick={handleSavePreferences} className="rounded-full font-didact_gothic" variant={'third'} >
              {t('configs.cookies.savePreferences')}
            </Button>

            <Button onClick={handleAcceptAll} className="rounded-full font-didact_gothic" variant={'first'}>
              {t('configs.cookies.acceptAll')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}