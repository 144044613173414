import { useState, useEffect } from "react"

import Loading from "../components/ui/loading";

export const LoadingProvider = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, [])

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <Loading />
    </>
  )
}