import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function divideEmail(email: string | null): string | null {
  if (email != null && email.length > 30) {
    let emailSplitted = email.split("@");

    return emailSplitted[0] + "\n@" + emailSplitted[1];
  }

  return email;
}

export const dateFormatter = new Intl.DateTimeFormat("pt-pt", {
  dateStyle: "medium",
  timeZone: "UTC",
});

export const currencyFormatter = new Intl.NumberFormat("pt-pt", {
  style: "currency",
  currency: "EUR",
});

// Define a function that takes a date as an argument
// and returns a string that represents how long ago the date was
export const timeAgo = (date: Date) => {
  const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return dateFormatter.format(date);
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 12) {
    return dateFormatter.format(date);
  }

  if (interval > 1) {
    return "há " + interval + " meses";
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return "há " + interval + " dias";
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return "há " + interval + " horas";
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return "há " + interval + " minutos";
  }

  if (seconds < 10) return "agora mesmo";

  return "há " + Math.floor(seconds) + " segundos";
};

function convertLanguageCode(language: string): string {
  const languageMap: { [key: string]: string } = {
    pt: "pt-PT",
    es: "es-ES",
    en: "en-US",
    fr: "fr-FR",
    de: "de-DE",
  };

  return languageMap[language] || "pt-PT";
}

export function GetDateInformation(date: Date, languageString: string = "pt") {
  const language = convertLanguageCode(languageString);

  const formattedDate = date.toLocaleString(language, {
    weekday: "long", // Display the full weekday name
    year: "numeric", // Display the year in numeric format
    month: "long", // Display the full month name
    day: "numeric", // Display the day of the month in numeric format
    hour: "numeric", // Display the hour in numeric format
    minute: "numeric", // Display the minute in numeric format
  });
  const formattedDateDay = date.toLocaleString(language, { day: "numeric" });
  const formattedDateMonth = date
    .toLocaleString(language, { month: "short" })
    .toUpperCase();
  const formattedDateYear = date.toLocaleString(language, { year: "numeric" });

  return {
    date: formattedDate,
    day: formattedDateDay,
    month: formattedDateMonth.replace(".", ""),
    year: formattedDateYear,
  };
}

export function getRandomDateWithinOneYear() {
  // Get today's date
  const today = new Date();

  // Get the date one year from today
  const oneYearFromToday = new Date(today);
  oneYearFromToday.setFullYear(oneYearFromToday.getFullYear() + 1);

  let randomDate;

  do {
    // Generate a random timestamp between today and one year from today
    const randomTimestamp =
      today.getTime() +
      Math.random() * (oneYearFromToday.getTime() - today.getTime());

    // Convert the timestamp back to a Date object
    randomDate = new Date(randomTimestamp);

    // Set time to 12:00 AM (midnight)
    randomDate.setHours(12, 30, 0, 0);

    // Repeat if the randomDate is not a Friday (5), Saturday (6), or Sunday (0)
  } while (![0, 5, 6].includes(randomDate.getDay()));

  return randomDate;
}
