// This hook is created to acess the create store modal in any where

import { create } from "zustand";

interface useLoadingStore {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const useLoading = create<useLoadingStore>((set) => ({
  isOpen: true,
  onOpen: () => set({ isOpen: true }),
  onClose: () => {
    setTimeout(() => {
      set({ isOpen: false });
    }, 1000);
  },
}));
