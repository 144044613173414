import loadingImg from "../../assets/logotipo/green-no_bg.webp";
import { useLoading } from "../../hooks/use-loading";
import { cn } from "../../lib/utils";


const Loading = () => {
  const loading = useLoading();
  return (
    <div style={{ transition: 'all 1s ease-in-out' }} className={cn("transition-all duration-[2000ms] ease-out inset-0 fixed h-dvh w-dvw z-50 flex items-center justify-center bg-pallete_3 text-pallete_3 text-2xl",
      loading.isOpen ? "translate-y-[0vh]" : "transform translate-y-[-121vh] translate-z-0")}>
      <img alt="Digital Wedding logo - a modern design representing wedding planning and event organization" className={cn("h-20 bounce2 transition-all duration-1000 ease-in ", loading.isOpen ? "" : "")} src={loadingImg} />
    </div>
  );
}

export default Loading;
