// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translation JSON files
import ptTranslation from "./locales/pt/translation.json";
import enTranslation from "./locales/en/translation.json";
import esTranslation from "./locales/es/translation.json";
// import frTranslation from "./locales/fr/translation.json";
// import deTranslation from "./locales/de/translation.json";

// Get the language from localStorage or default to 'pt'
const storedLanguage = localStorage.getItem("i18nextLng") || "pt";

i18n
  .use(initReactI18next) // Integrates with React
  .init({
    resources: {
      pt: {
        translation: ptTranslation,
      },
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
      // fr: {
      //   translation: frTranslation,
      // },
      // de: {
      //   translation: deTranslation,
      // },
    },
    lng: storedLanguage, // Use the stored language or default to 'pt'
    fallbackLng: "pt",
    interpolation: {
      escapeValue: false, // React already handles escaping
    },
  });

export default i18n;
